import React from "react";
import debounce from "lodash.debounce";

// mui
import { IconButton, InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

// components
import Textfield from "./textfield";

function SearchTextField({ searchClick = () => {}, ...props }) {
  const [searchChange, setSearchChange] = React.useState("");

  // Debounce the searchClick function
  const debouncedSearchClick = React.useMemo(
    () => debounce((query) => searchClick(query), 800), // Debounce delay set to 500 milliseconds
    [searchClick]
  );

  const handleSearchChange = (event) => {
    const newValue = event.target.value;
    setSearchChange(newValue);
    debouncedSearchClick(newValue);
  };

  return (
    <Textfield
      variant={"outlined"}
      color={"primary"}
      label={"Search"}
      type={"text"}
      value={searchChange}
      size={"small"}
      onChange={handleSearchChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              disabled={!searchChange}
              onClick={() => searchClick(searchChange)}
              size="small"
              style={{ cursor: "pointer", zIndex: 100 }}
            >
              <SearchIcon style={{ color: "#3374B9" }} />
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
}

export default React.memo(SearchTextField);
